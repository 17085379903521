import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We’re so thrilled to welcome Jessie Kitchens to the SuperBot stage this spring. As a conversation designer on `}<a parentName="p" {...{
        "href": "https://tech.fb.com/ar-vr/"
      }}>{`Facebook’s AR/VR team`}</a>{`, she gets to work on some of the most cutting edge interactions. Learn more about her here!`}</p>
    <h3>{`Tell me about yourself`}</h3>
    <p>{`I’m a maker of things, a tender of gardens, a teller of stories, and a parent. And also I design conversational interfaces.`}</p>
    <h3>{`What got you interested in conversation design? How did you become a conversational designer?`}</h3>
    <p>{`After getting my MFA in Acting, I started doing more writing and sketch comedy. This led to joining a company that was doing interactive narrative design, primarily for kids. As the chatbot and voice assistant field exploded, it was a natural segue for the company to partner with brands to produce a broader set of content, from customer service to feature discovery to launch campaigns. After that I spent some time at Amazon developing new hardware products and now I’m working with the AR/VR org at Facebook.`}</p>
    <h3>{`What are some key tips you would give someone building a chatbot or voice skill, in terms of conversation design?`}</h3>
    <ol>
      <li parentName="ol">{`Get to know the field. Investigate a wide variety of bots and voice apps to figure out what works, what doesn’t, and why. When you find something that works, try to reverse engineer it to understand the structure, the edge cases, and error handling. Reflect on what is so satisfying about it.`}</li>
      <li parentName="ol">{`Be deeply curious about your users—their needs, their environment, their obstacles, their context.`}</li>
      <li parentName="ol">{`Test it on real people! It is impossible to see something clearly when you’ve been working on it in depth, so make sure you’re doing user testing and research. You don’t need to hire a firm, identify a diverse set of people in your life that you can count on to try things out and give honest feedback.`}</li>
    </ol>
    <h3>{`What skills do you think are most valuable to a conversation designer?`}</h3>
    <p>{`Empathy, curiosity, attention to detail.`}</p>
    <h3>{`What are you looking forward to SuperBot?`}</h3>
    <p>{`Listening to all the other speakers! It’s an incredible slate, and I’m so excited to hear other peoples’ perspectives and to challenge my own.`}</p>
    <hr></hr>
    <h3>{`About Facebook’s AR/VR Team`}</h3>
    <p><em parentName="p">{`The AR/VR team at Facebook helps people around the world collapse physical distance, come together, and connect by building world-class consumer hardware and bleeding-edge virtual and augmented reality software.`}</em>{` `}<em parentName="p">{`To learn more about how Jessie`}</em>{` `}<em parentName="p">{`makes that dream a reality, register for SuperBot today.`}</em></p>
    <div className="wp-block-button aligncenter is-style-squared">
  [Register for SuperBot](https://www.dashbot.io/superbot/sb2020)
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      